// UpdateMembership.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateUserDetails = ({
  open,
  user,
  onHide,
  membershipOptions,
  onMembershipUpdate,
  refetchUsers,
  closeUserDetails,
}) => {
  const [selectedMembership, setSelectedMembership] = useState(user.membership);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  console.log("user", user);
  const handleSubmit = () => {
    // onSubmit(user.id, selectedMembership);
    handleUpdate();
    onHide();
  };

  const [formData, setFormData] = useState({
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    phone: user.phone,
    address: user.address,
    visibility: user.visibility,
    membership: user.membership,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    console.log("Formdata", formData);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/users/${user.id}`,
        {
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the JWT token
          },
        }
      );
      console.log("response", response.status);
      setSnackbarMessage("User Details updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      if (response.status === 200) {
        refetchUsers();
        closeUserDetails();
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update membership.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  console.log("sleected", user);

  return (
    <Dialog open={open} onClose={onHide} fullWidth maxWidth="sm">
      <DialogTitle>Update User Details</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          name="firstname"
          value={formData.firstname}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Last Name"
          name="lastname"
          value={formData.lastname}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
        />
        <TextField
          select
          label="Visibility"
          name="visibility"
          value={formData.visibility}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </TextField>
        <TextField
          label="Current Membership"
          value={user.membership} // Display the current membership
          fullWidth
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          select
          label="Select Membership"
          name="membership"
          value={formData.membership}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
        >
          {membershipOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onHide} color="primary">
          Cancel
        </Button> */}
        <Button onClick={handleSubmit} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserDetails;
