import React,{useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import TopNavBar from "../../components/TopNavBar";
import Sidebar from "../../components/Sidebar";
import MoreIcon from "../../../src/assets/png/More.png";
import StaffDemoImage from "../../../src/assets/png/StaffDemoImage.png";
import EditButtonIcon from "../../../src/assets/png/EditButtonIcon.png";
import DeleteButtonIcon from "../../../src/assets/png/DeletebuttonIcon.png";
import { ReactComponent as EditSvgIcon } from "../../../src/assets/svg/EditSvgIcon.svg";
import axios from 'axios'
import { useQuery } from "react-query";
import "./Staff.css"
import { fetchAllData } from "../../utils/fetchData";
import Pagination from "../../components/navbarwidgets/Pagination";
import LoadingOverlay from 'react-loading-overlay-ts';
const apiURL = process.env.REACT_APP_API_URL;


function Staff() {
  const staffEndpoint = '/v1/staffs'
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(['staffMembers',staffEndpoint],()=>fetchAllData(staffEndpoint))
  const [staffList, setStaffList] = useState([]);
  const [fileChange, setFileChange] = useState(false);
  const [showExtraClass, setShowExtraClass] = useState(true); //usestate for animation
 
  const sortedStaffData = staffList.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  // pagination code
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; 
  const totalItems = staffList.length; 
  //console.log(totalItems)

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newStaffList = sortedStaffData.slice(startIndex, endIndex);
  console.log(newStaffList)
  // Code for pagination ends
  // code for animation
  useEffect(() => {
    const handleResize = () => {
      setShowExtraClass(window.innerWidth > 450);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // code for animation ends

  if(isLoading ){
          return (
          <>
          <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading your content...'
    >
            <div className="navBarWrapper">
              <Sidebar />
              {/* <==> to homecontainer */}
              <div className="contentWrapper">
                <TopNavBar />
                {/* Content of the page */}
                <div className="meetingRoomWrapper">
                  <div className="topMeetingRoomSection">
                    <button className="addNewButton">
                      <Link to="/addstaff">
                        <span>
                          <img src={MoreIcon} alt="" />
                        </span>{" "}
                        Add new
                      </Link>
                    </button>
                    <h3>Staff({staffList?.length || 0})</h3>
                  </div>
      
                  {/*  */}
                  <div className="meetingCollectionWrapper">
                    <p>Loading...</p>
                  </div>
                </div>
                {/*End of content  */}
              </div>
            </div>
            </LoadingOverlay>
          </>
    );
  }



  else{
    console.log(data)
    if(!staffList.length && data?.length){
      setStaffList(structuredClone(data));
    }

    const token = localStorage.getItem("token");

    const handleEditStaff = (staffPerson) =>{
      navigate('/editstaff',{state:staffPerson})
    }

    const handleOndeleteClick = async (event,id) =>{

      event.preventDefault();

      try{
        const response = await axios.delete(`${apiURL}/v1/staffs/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if(response.statusText === 'OK'){
          if(staffList.length===1){
            data.length=0;
          }
          setStaffList(prevStaffList=>prevStaffList.filter(staff=>staff.id!==id))
          window.location.reload();
        }
      }catch(error){
        console.log(error)
      }
    }
  
    return (
      <>
      <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading your content...'
    >
        <div className="navBarWrapper">
          <Sidebar />
          {/* <==> to homecontainer */}
          <div className="contentWrapper">
            <TopNavBar />
            {/* Content of the page */}
            <div className="meetingRoomWrapper">
              <div className="topMeetingRoomSection">
                <button className="addNewButton">
                  <Link to="/addstaff">
                    <span>
                      <img src={MoreIcon} alt="" />
                    </span>{" "}
                    Add new
                  </Link>
                </button>
                <h3>Staff({staffList?.length || 0})</h3>
              </div>
  
              {/*  */}
              <div className="meetingCollectionWrapper">

                {/*fetched data*/}
                
                {newStaffList.length ? newStaffList.map((staffPerson,index)=>{

                  return (
                    <div key={index} className={`meetingDiv ${
                      showExtraClass ? "extraClass" : ""
                    }`}>
                    <img
                      height='51%'
                      width='100%'
                      src={staffPerson.imageUrl}
                      alt="image here"
                      className="meetingRoomImgClass"
                    />
                    <div className="meetingDetailWrapper">
                      <h2>{staffPerson.name}</h2>
                      <p>
                        {staffPerson.description}
                      </p>
                      <div className="buttonSection">
                        <button onClick={(event)=>{handleEditStaff(staffPerson)}}>
                          {" "}
                          {/* <Link to="/editstaff"> */}
                            <span>
                              <EditSvgIcon/>
                            </span>
                            Edit
                          {/* </Link> */}
                        </button>
                        <button onClick={(event)=>{handleOndeleteClick(event,staffPerson.id)}}>
                          <span>
                            <img src={DeleteButtonIcon} alt="" />
                          </span>
                          Delete
                        </button>
                        </div>
                    </div>
                  </div>
                  )
                }): <h3 style={{fontFamily: "Inter", fontSize:'18px',fontWeight:'400'}}>There is currently no Staff to display</h3>}
              </div>
            </div>
            {(staffList.length > 6) &&
            <div className="pagWrapper margExtra">
        <Pagination 
         currentPage={currentPage}
         totalPages={totalPages}
         onPrevious={handlePrevious}
         onNext={handleNext}
        />
        </div>
  }
            {/*End of content  */}
          </div>
        </div>
        </LoadingOverlay>
      </>
    );
  }
}

export default Staff;
