// ReviewRow.js
import React, { useState } from "react";
import ToggleSwitch from "../../../components/UIComponent/ToggleSwitch";
import axios from "axios";
import { toast } from "react-toastify";
import { extractFirstValue } from "../../../utils/Asterisk";
import StarIcon from "../StarIcon";
import "./RoomReviewMod.css";

const RoomReviewRow = ({ item, index, refreshData }) => {
  const [isDisabled, setIsDisabled] = useState(item.isDisabled);
  const [loading, setLoading] = useState(false);
  console.log("isDisabled", isDisabled);

  const handleToggle = async () => {
    const updatedIsDisabled = !isDisabled; // Get the new isDisabled value

    try {
      setLoading(true);
      // Update the review visibility via the API
      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/reviews/updateVisibility/${item.id}`,
        {
          isDisabled: updatedIsDisabled,
        }
      );

      // Update local state with the new visibility value
      setIsDisabled(updatedIsDisabled);
      refreshData(); // Refresh the data if needed
    } catch (error) {
      console.error(error.message);
      toast.error("Error updating review visibility:", error.message);
    } finally {
      setLoading(false);
    }
  };

  console.log("item.booking", item, item?.booking);

  const renderStarIcons = () => {
    const stars = [];
    for (let i = 0; i < item.rating; i++) {
      stars.push(
        <StarIcon
          key={i} // unique key for each star
          color="#FFD700" // Gold color for filled stars
          width="20" // Adjust width for the star size
          height="20"
        />
      );
    }
    return <div className="stars-container">{stars}</div>;
  };

  return (
    <tr key={index}>
      <td>{extractFirstValue(item?.room?.title, "|")}</td>
      <td>{item?.meetingBooking?.checkInDate}</td>
      <td>{item?.meetingBooking?.checkInTime}</td>
      <td>{item?.meetingBooking?.duration} hours</td>
      <td className="rating-icons">{renderStarIcons()}</td>
      <td>{item?.description}</td>
      <td>{item?.meetingBooking?.isReviewed ? "Reviewed" : "Not Reviewed"}</td>
      <td>
        <ToggleSwitch
          defaultChecked={isDisabled} // Use isDisabled state directly
          onChange={handleToggle} // Handle toggle change
          disabled={loading} // Disable while loading
        />
      </td>
    </tr>
  );
};

export default RoomReviewRow;
