import { useRef, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import "./CreatePolicy.css";
import { FileUpload } from "primereact/fileupload";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UploadIcon } from "../../../assets/svg/upload.svg";
import axios from "axios";
import { ToastContainer, toast, useToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToggleSwitch from "../../../components/UIComponent/ToggleSwitch";
import NavigateBack from "../../../utils/NavigateBack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomCloseIcon from "../../../utils/CustomCloseIcon";

const CreatePolicy = ({ open, handleClose }) => {
  const [fileDetails, setFileDetails] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const [formDetails, setFormDetails] = useState({
    policyName: "",
    policyVersion: "",
    policyFormat: "",
    policyUploadDate: "",
    policyValidityDate: "",
    visibility: false,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileDetails(file);
    } else {
      setFileDetails(null);
    }
  };

  const handleInput = (e) => {
    setFormDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleDropdownSelect = (value) => {
    setFormDetails((prev) => ({
      ...prev,
      policyFormat: value,
    }));
    setShowDropdown(false);
  };

  const handleVisibilityChange = (checked) => {
    console.log("checked", checked);
    setFormDetails((prev) => ({
      ...prev,
      visibility: checked,
    }));
  };

  function checkFileType(file) {
    const mimeType = file.type;

    switch (mimeType) {
      case "image/png":
        return "png";
      case "image/jpg":
        return "jpg";
      case "image/jpeg":
        return "jpeg";
      case "application/pdf":
        return "pdf";
      case "text/plain":
        return "txt";
      default:
        return "unknown";
    }
  }


  const submitHandler = async (e) => {
    e.preventDefault();
    const { policyName, policyVersion, policyFormat } = formDetails;
    if (!policyName || !policyVersion || !policyFormat || !fileDetails) {
      toast.error("Please fill in all required fields!");
      return;
    }

    const fileType = checkFileType(fileDetails);

    console.log("fileType", fileType, policyFormat.toLocaleLowerCase());
    if (fileType !== policyFormat.toLocaleLowerCase()) {
      toast.error("Please upload correct format");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", fileDetails);

      const fileUpload = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/files`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("fileUpload response", fileUpload);

      const updatedFormDetails = {
        ...formDetails,
        policyFile: fileUpload.data.body[0]?.filename,
      };

      console.log("updatedFormDetails", updatedFormDetails);

      const createPolicyResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/policy`,
        updatedFormDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("createPolicyResponse", createPolicyResponse);
      if (createPolicyResponse.status === 201) {
        toast.success("Policy has been successfully created!");
        handleClose()
      }
    } catch (error) {
      if (error.response) {
        const errorMessages = error.response.data?.response?.message; // Safely access the message array
    
        // Log the error for debugging
        console.log("error", errorMessages);
    
        // Check if errorMessages is an array and join them into a single string
        if (Array.isArray(errorMessages)) {
          const joinedMessages = errorMessages.join(", "); // Join messages with a comma and space
          toast.error(joinedMessages); // Display the combined message
        } else {
          // If it's not an array or undefined, display a generic error message
          toast.error("Error creating Policy: " + (error.response.data.error || "Unknown error"));
        }
      } else {
        // Handle other types of errors (like network errors)
        toast.error("Error creating Policy: " + error.message);
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px", // Example: adding padding
            borderRadius: "10px", // Example: making rounded corners
            position: "relative",
          },
        }}
      >
        {/* <DialogTitle>Create Policy</DialogTitle> */}
        <div
          className="closeIconContainer"
          style={{ position: "absolute", right: 20, cursor: "pointer" }}
        >
          <CustomCloseIcon fill={"#FF0000"} onClick={handleClose} />
        </div>
        <DialogContent>
          <div className="createPolicyForm">
            <form onSubmit={submitHandler}>
              <div className="policyFields">
                <div className="policyField one">
                  <label htmlFor="" className="policyLabel">
                    Policy Name*
                  </label>
                  <input
                    onChange={handleInput}
                    type="text"
                    name="policyName"
                    placeholder="Policy Name"
                    value={formDetails?.policyName}
                  />
                </div>
                <div className="policyField one">
                  <label htmlFor="" className="policyLabel">
                    Policy Version*
                  </label>
                  <input
                    onChange={handleInput}
                    type="text"
                    name="policyVersion"
                    placeholder="Version Name"
                    value={formDetails?.policyVersion}
                  />
                </div>
                <div
                  className="policyField one"
                  style={{ position: "relative" }}
                >
                  <label htmlFor="" className="policyLabel">
                    Policy Format*
                  </label>
                  <input
                    onChange={handleInput}
                    onClick={handleInputClick}
                    type="text"
                    name="policyFormat"
                    placeholder="Policy File type"
                    value={formDetails?.policyFormat}
                    readOnly
                  />
                  {showDropdown && (
                    <div className="dropdown">
                      <div
                        className="dropDownoption"
                        onClick={() => handleDropdownSelect("JPG")}
                      >
                        JPG
                      </div>
                      <div
                        className="dropDownoption"
                        onClick={() => handleDropdownSelect("PDF")}
                      >
                        PDF
                      </div>
                      <div
                        className="dropDownoption"
                        onClick={() => handleDropdownSelect("PNG")}
                      >
                        PNG
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="policyField one"
                  style={{ position: "relative" }}
                >
                  <label htmlFor="" className="policyLabel">
                    Upload Date*
                  </label>
                  <input
                    onChange={handleInput}
                    // onClick={handleInputClick}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    name="policyUploadDate"
                    placeholder="Upload Date"
                    value={formDetails?.policyUploadDate}
                  />
                </div>
                <div
                  className="policyField one"
                  style={{ position: "relative" }}
                >
                  <label htmlFor="" className="policyLabel">
                    Valid until*
                  </label>
                  <input
                    onChange={handleInput}
                    // onClick={handleInputClick}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    name="policyValidityDate"
                    placeholder="Valid until"
                    value={formDetails?.policyValidityDate}
                  />
                </div>
                <div
                  className="policyField file"
                  style={{ position: "relative" }}
                >
                  <label htmlFor="" className="policyLabel">
                    Upload Policy*
                  </label>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/png, image/jpeg, application/pdf"
                  />
                  <div className="uploadPolicy">
                    <span className="uploadButton">
                      <UploadIcon />
                      Upload
                    </span>
                  </div>
                  {fileDetails && (
                    <span className="uploaded-file-details">
                      {fileDetails?.name}
                    </span>
                  )}
                </div>
                <div className="visibilityToggle">
                  <label htmlFor="" className="policy_toggle_label">
                    Visibility
                  </label>
                  <div className="switchContainer">
                    <ToggleSwitch
                      defaultChecked={formDetails.visibility}
                      onChange={handleVisibilityChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={submitHandler}
            sx={{
              width: "10%",
              height: "32px",
              backgroundColor: "#66a3a6",
              border: "none",
              color: "white",
              padding: "2% 6%%",
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              gap: "2%",
              borderRadius: "5px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#559294", // Change hover color if needed
              },
            }}
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreatePolicy;
