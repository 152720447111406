import React, { useEffect, useState } from "react";
import TopNavBar from "../../components/TopNavBar";
import Sidebar from "../../components/Sidebar";

import MoreIcon from "../../../src/assets/png/More.png";
import NewsDemoIcon from "../../../src/assets/png/NewsDemoIcon.png";
import EditButtonIcon from "../../../src/assets/png/EditButtonIcon.png";
import DeleteButtonIcon from "../../../src/assets/png/DeletebuttonIcon.png";
import { ReactComponent as EditSvgIcon } from "../../../src/assets/svg/EditSvgIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { fetchAllData } from "../../utils/fetchData";
import Pagination from "../../components/navbarwidgets/Pagination";
import LoadingOverlay from "react-loading-overlay-ts";
import LoaderSpinner from "../../utils/LoaderSpinner";

function News() {
  const apiURL = process.env.REACT_APP_API_URL;
  const newsEndpoint = "/v1/news";
  const { data, isLoading } = useQuery(["News", newsEndpoint], () =>
    fetchAllData(newsEndpoint)
  );
  const [newsData, setNewsData] = useState([]);
  const [showExtraClass, setShowExtraClass] = useState(true); //usestate for animation
  const navigate = useNavigate();

  const sortedNewsData = newsData.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  // pagination code
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = newsData.length;
  //console.log(totalItems)

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newNewsData = sortedNewsData.slice(startIndex, endIndex);
  console.log(newNewsData);
  // Code for pagination ends

  // code for animation
  useEffect(() => {
    const handleResize = () => {
      setShowExtraClass(window.innerWidth > 450);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // code for animation ends

  if (isLoading) {
    return (
      <>
        {isLoading && (
          <div className="spinner-overlay">
            <LoaderSpinner />
          </div>
        )}
        <div className="navBarWrapper">
          <Sidebar />
          {/* demo example */}
          {/* <==> to homecontainer */}
          <div className="contentWrapper">
            <TopNavBar />
            {/* Content of the page */}
            <div className="meetingRoomWrapper">
              <div className="topMeetingRoomSection">
                <button className="addNewButton">
                  <Link to="/addnewnews">
                    <span>
                      <img src={MoreIcon} alt="" />
                    </span>{" "}
                    Add new
                  </Link>
                </button>
                {/* <h3>News({newsData.length})</h3> */}
              </div>
              <div className="meetingCollectionWrapper">
                <p>Loading...</p>
              </div>
            </div>
            {/*End of content  */}
          </div>
        </div>
      </>
    );
  } else {
    const token = localStorage.getItem("token");
    if (!newsData.length && data?.length) {
      setNewsData(data);
    }

    // edit news
    const editNewshandle = (news) => {
      navigate("/editnews", { state: news });
    };
    // delete functionality
    const deleteNewshandle = async (event, id) => {
      event.preventDefault();
      // console.log(index);

      try {
        // const token = localStorage.getItem("token");
        const response = await axios.delete(`${apiURL}/v1/news/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        });
        if (response.statusText === "OK") {
          if (newsData.length === 1) {
            data.length = 0;
          }
          setNewsData((prevnewsData) =>
            prevnewsData.filter((news) => news.id !== id)
          );
        }
        //alert(`${deletedNews.title} is deleted`);
        window.location.reload();
        //console.log("connected to delete functionality", newsId);
      } catch (error) {
        console.error("Error deleting meeting:", error.message);
      }
    };

    return (
      <>
 {isLoading && (
          <div className="spinner-overlay">
            <LoaderSpinner />
          </div>
        )}
          <div className="navBarWrapper">
            <Sidebar />
            {/* <==> to homecontainer */}
            <div className="contentWrapper">
              <TopNavBar />
              {/* Content of the page */}
              <div className="meetingRoomWrapper">
                <div className="topMeetingRoomSection">
                  <button className="addNewButton" id="addNewItemId">
                    <Link to="/addnewnews">
                      <span>
                        <img src={MoreIcon} alt="" />
                      </span>{" "}
                      Add new
                    </Link>
                  </button>
                  <h3 id="numberOfItemsId">News({newsData?.length || 0})</h3>
                </div>

                {/*  */}
                <div className="meetingCollectionWrapper">
                  {newsData.length === 0 ? (
                    <p>There is currently no news to display</p>
                  ) : (
                    newNewsData.map((news, index) => (
                      <div
                        key={news.id}
                        className={`meetingDiv ${
                          showExtraClass ? "extraClass" : ""
                        }`}
                        id="itemCardId"
                      >
                        <div className="hovercolorbubble"></div>
                        <img
                          height="51%"
                          width="100%"
                          src={news.imageUrl}
                          alt=""
                          className="meetingRoomImgClass"
                        />
                        <div className="meetingDetailWrapper">
                          <h2>{news.title}</h2>
                          <p>{news.description}</p>
                          <div className="buttonSection">
                            <button
                              onClick={(event) => {
                                editNewshandle(news);
                              }}
                            >
                              {" "}
                              <span>
                                <EditSvgIcon />
                              </span>
                              Edit
                            </button>
                            <button
                              onClick={(event) => {
                                deleteNewshandle(event, news.id);
                              }}
                            >
                              <span>
                                <img src={DeleteButtonIcon} alt="" />
                              </span>
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              {newsData.length > 6 && (
                <div className="pagWrapper margExtra">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPrevious={handlePrevious}
                    onNext={handleNext}
                  />
                </div>
              )}
              {/*End of content  */}
            </div>
          </div>
      </>
    );
  }
}

export default News;
