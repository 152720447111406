import React, { useEffect, useState } from "react";
import "./Coaches.css";
import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import axios from "axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import MoreIcon from "../../../src/assets/png/More.png";
import EditButtonIcon from "../../../src/assets/png/EditButtonIcon.png";
import DeleteButtonIcon from "../../../src/assets/png/DeletebuttonIcon.png";
import { ReactComponent as EditSvgIcon } from "../../../src/assets/svg/EditSvgIcon.svg";
import { Link } from "react-router-dom";
import "./Coaches.css";
import { fetchAllData } from "../../utils/fetchData";
import Pagination from "../../components/navbarwidgets/Pagination";
import LoaderSpinner from "../../utils/LoaderSpinner";
const apiURL = process.env.REACT_APP_API_URL;

function Coaches() {
  const coachesEndpoint = "/v1/coaches";
  const { data, isLoading } = useQuery(["Coaches", coachesEndpoint], () =>
    fetchAllData(coachesEndpoint)
  );
  const [coachesList, setCoachesList] = useState([]);
  const [showExtraClass, setShowExtraClass] = useState(true); //usestate for animation
  const navigate = useNavigate();

  const sortedCoachesData = coachesList?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  // pagination code
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = coachesList?.length;
  //console.log(totalItems)

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newCoachesList = sortedCoachesData?.slice(startIndex, endIndex);
  console.log(newCoachesList);
  // Code for pagination ends

  // code for animation
  useEffect(() => {
    setCoachesList(data);
  }, [data]);
  useEffect(() => {
    const handleResize = () => {
      setShowExtraClass(window.innerWidth > 450);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // code for animation ends

  if (isLoading) {
    return (
      <>
        <div>
          {isLoading && (
            <div className="spinner-overlay">
              <LoaderSpinner />
            </div>
          )}
          <div className="navBarWrapper">
            <Sidebar />
            {/* <==> to homecontainer */}
            <div className="contentWrapper">
              <TopNavBar />
              {/* Content of the page */}
              <div className="meetingRoomWrapper">
                <div className="topMeetingRoomSection">
                  <button className="addNewButton" id="addNewItemId">
                    <Link to="/addnewcoaches">
                      <span>
                        <img src={MoreIcon} alt="" />
                      </span>{" "}
                      Add new
                    </Link>
                  </button>
                </div>

                {/*  */}
                <div className="meetingCollectionWrapper">
                  <p>Loading...</p>
                </div>
              </div>
              {/*End of content  */}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    const token = localStorage.getItem("token");
    if (!coachesList?.length && data?.length) {
      setCoachesList(data);
    }

    const handleEditCoach = (coach) => {
      navigate(`/editcoaches/${coach.id}`);
    };

    const handleOndeleteClick = async (event, id) => {
      event.preventDefault();
      //console.log(index)

      try {
        const response = await axios.delete(`${apiURL}/v1/coaches/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.statusText === "OK") {
          if (coachesList?.length === 1) {
            data.length = 0;
          }
          setCoachesList((prevCoachesList) =>
            prevCoachesList.filter((coach) => coach.id !== id)
          );
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    };
    return (
      <>
        {/* */}
        <div className="navBarWrapper">
          <Sidebar />
          {/* <==> to homecontainer */}
          <div className="contentWrapper">
            <TopNavBar />
            {/* Content of the page */}
            <div className="forScroll">
              <div className="meetingRoomWrapper">
                <div className="topMeetingRoomSection">
                  <button className="addNewButton" id="addNewItemId">
                    <Link to="/addnewcoaches">
                      <span>
                        <img src={MoreIcon} alt="" />
                      </span>{" "}
                      Add new
                    </Link>
                  </button>
                  <h3>Coaches({coachesList?.length || 0})</h3>
                </div>
                <div className="meetingCollectionWrapper">
                  {/* api intergration */}
                  {newCoachesList?.length ? (
                    newCoachesList.map((coach, index) => {
                      return (
                        <div
                          key={coach.id}
                          className={`meetingDiv ${
                            showExtraClass ? "extraClass" : ""
                          }`}
                          id="itemCardId"
                        >
                          {/* <div className="imgCssClass"> */}
                          <img
                            height="51%"
                            width="100%"
                            src={coach.imageUrl}
                            alt=""
                            className="meetingRoomImgClass"
                          />
                          {/* </div> */}
                          <div className="meetingDetailWrapper">
                            <h2>{coach.title}</h2>
                            <p>{coach.description}</p>
                            <div className="buttonSection">
                              <button
                                onClick={(event) => {
                                  handleEditCoach(coach);
                                }}
                              >
                                {" "}
                                {/* <Link to="/editcoaches"> */}
                                <span>
                                  <EditSvgIcon />
                                </span>
                                Edit
                                {/* </Link> */}
                              </button>
                              <button
                                onClick={(event) => {
                                  handleOndeleteClick(event, coach.id);
                                }}
                              >
                                <span>
                                  <img src={DeleteButtonIcon} alt="" />
                                </span>
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h3
                      style={{
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontWeight: "400",
                      }}
                    >
                      There is currently no Coaches to display
                    </h3>
                  )}
                </div>
                {coachesList?.length > 6 && (
                  <div className="pagWrapper margExtra">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPrevious={handlePrevious}
                      onNext={handleNext}
                    />
                  </div>
                )}
              </div>
            </div>
            {/*End of content  */}
          </div>
        </div>
      </>
    );
  }
}

export default Coaches;
