import React, { useEffect, useState } from "react";
import "./UserRequests.css";
import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import { useQuery } from "react-query";
import { fetchAllData } from "../../utils/fetchData";
import LoadingOverlay from "react-loading-overlay-ts";
import axios from "axios";
import Pagination from "../../components/navbarwidgets/Pagination";
import UserApprovalTable from "./UserApprovalTable";
import LoaderSpinner from "../../utils/LoaderSpinner";
const apiURL = process.env.REACT_APP_API_URL;

function UserRequests() {
  const [usersData, setUsersData] = useState([]);
  const [isResponseOk, setIsResponseOk] = useState({});
  const userDataEndpoint = "/v1/users/allusers";
  
  const { data,isLoading,refetch } = useQuery(
    ["userReqData", userDataEndpoint],
    () => fetchAllData(userDataEndpoint),
    {
      onSuccess: (data) => {
        setUsersData(data.data);
      },
    }
  );

  useEffect(() => {
    if (Array.isArray(data)) {
      const initialStatus = {};
      data.forEach((user) => {
        initialStatus[user.id] = false;
      });
      setIsResponseOk(initialStatus);
    }
  }, [data]);

  const handleReqToggle = async (email, userId, visibility) => {
    try {
      const token = localStorage.getItem("token");
      let response;
      if (visibility === "inactive") {
        response = await axios.post(
          `${apiURL}/v1/auth/validate`,
          { email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
          }
        );
      } else {
        response = await axios.post(
          `${apiURL}/v1/auth/invalidate`,
          { email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
          }
        );
      }

      setIsResponseOk((prevStatus) => ({
        ...prevStatus,
        [userId]: response.status === 200,
      }));
      await refetch();
    } catch (error) {
      console.error("error:", error);
      setIsResponseOk(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <LoaderSpinner />       
      </>
    );
  }

  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          <div className="userApprovalWrapper">
            {/* Using the PrimeReact DataTable component */}
            <UserApprovalTable
              usersData={usersData}
              handleReqToggle={handleReqToggle}
              refetchUsers={refetch}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserRequests;
